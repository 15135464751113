<template>
  <div>
    <el-row>
      <el-button type="primary" @click="downloadFun">导出</el-button>
      <el-button type="text" @click="downloadListFun">导出记录</el-button>
    </el-row>
    <!-- 导出 -->
    <el-dialog v-model="download.visible" width="40%" center title="导出" @close="downloadClose">
      <el-row>请选择明细导出范围</el-row>
      <el-row>
        <el-radio v-model="download.all" :label="false">导出当前页全部数据</el-radio>
        <el-radio v-model="download.all" :label="true">导出全部数据</el-radio>
      </el-row>
      <template #footer class="dialog-footer">
        <el-button @click="download.visible=false">取消</el-button>
        <el-button type="primary" @click="downloadSubmit">确定</el-button>
      </template>
    </el-dialog>
    <!-- 导出记录 -->
    <el-dialog v-model="downloadList.visible" width="60%" center title="导出记录" @open="downloadListOpen">
      <el-table :data="downloadList.data" border>
        <el-table-column prop="data_size" label="数量"> </el-table-column>
        <el-table-column prop="status_name" label="导出状态"> </el-table-column>
        <el-table-column prop="creator_name" label="操作人"> </el-table-column>
        <el-table-column prop="create_time" label="操作时间"> </el-table-column>
        <el-table-column label="导出操作">
          <template #default="scope">
            <el-button v-if="scope.row.status === 1" type="text" @click="downloadFile(scope.row.file_url)">点击下载文件</el-button>
        </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="downloadListChange"
        :current-page="downloadList.page.page_index"
        :page-size="downloadList.page.page_size"
        layout="prev, pager, next, jumper"
        :total="downloadList.page.page_total">
      </el-pagination>
    </el-dialog>
  </div>
</template>
<script setup>
  import { reactive, defineProps } from 'vue'
  import { ElMessage } from 'element-plus'
  import { exportLogList } from '@/api/jiushi'
  const props = defineProps({
    export_type: {
      type: String,
      default: ''
    },
    params: {
      type: Object,
      default: () => {}
    },
    page_index: {
      type: [String, Number],
      default: ''
    },
    page_size: {
      type: [String, Number],
      default: ''
    },
    list_type: {
      type: [String, Number],
      default: ''
    },
    api: {
      type: Function,
      default: () => {}
    }
  })

  const download = reactive({
    visible: false,
    all: false
  })

  const downloadList = reactive({
    visible: false,
    data: [],
    page: {
      page_index: 1,
      page_size: 10,
      page_total: 0
    }
  })

  const downloadListChange = v => {
    getDownloadList(v)
  }

  const downloadFun = () => {
    download.visible = true
  }
  const downloadListFun = () => {
    downloadList.visible = true
  }
  const getDownloadList = async v => {
    const { page_index, page_size } = downloadList.page
    const { list_type: type } = props
    const res = await exportLogList({ type, page_index, page_size })
    const { data, page_count } = res
    downloadList.data = data.map(item => ({ ...item, status_name: !item.status ? '正在导出' : item.status === 1 ? '导出成功' : '导出失败' }))
    downloadList.page.page_total = page_count
  }
  const downloadListOpen = async () => {
    getDownloadList(1)
  }
  const downloadSubmit = async () => {
    const { page_index, page_size, params, api, export_type } = props
    const _params = { export_type, page_index, page_size, ...params, all: download.all }
    const res = await api(_params)
    downloadClose()
    ElMessage.success(res)
  }
  const downloadFile = (v) => {
    const fileUrl = v
    const link = document.createElement('a')
    link.href = fileUrl
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const downloadClose = () => {
    download.visible = false
  }

</script>
