<!--
 * @Description: 店铺管理 > 结算明细
 * @Author: zhaobin
 * @Date: 2023-02-24 10:12:38
 * @LastEditors: goodLiang
 * @LastEditTime: 2024-02-29 10:48:56
-->
<script>
import { yeepayShopSettlementList } from '@/api/shop'
import { asynchronousExportOfSettlementDetails } from '@/api/jiushi'

import ExportLog from './components/ExportLog.vue'

export default {
  name: 'shop-settlement-details',
  components: { ExportLog },
  data() {
    return {
      user_id: this.$route.params.id,
      page_index: 1,
      page_size: 20,
      total: 0,
      tableData: [],
      form: {
        time: [],
        order_no: ''
      }
    }
  },
  computed: {
    exportParams() {
      return {
        start_time: this.form.time && (this.form.time[0] || ''),
        end_time: this.form.time && (this.form.time[1] || ''),
        order_no: this.form.order_no || null,
        user_id: this.user_id
      }
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    exportApi() {
      return asynchronousExportOfSettlementDetails
    },
    search(keep) {
      if (!keep) this.page_index = 1
      const params = {
        page_index: this.page_index,
        page_size: this.page_size,
        user_id: this.user_id
      }
      this.appendPostData(params, this.form)
      yeepayShopSettlementList(params).then((res) => {
        const { data } = res
        if (!data) return
        this.total = res.page_count
        this.tableData = data
      })
    },
    resetForm() {
      this.$refs.searchForm.resetFields()
      this.search()
    },
    appendPostData(postParams, obj) {
      for (const i in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, i)) {
          switch (i) {
            case 'time':
              if (obj[i]) {
                postParams.start_time = obj[i][0]
                postParams.end_time = obj[i][1]
              }
              break
            default:
              if (obj[i] !== '') {
                postParams[i] = obj[i]
              }
          }
        }
      }
    }
  }
}
</script>
<template>
  <div class="app-container">
    <CustomCard>
      <FoldPanel>
        <el-form
          :model="form"
          label-position="left"
          label-width="110px"
          ref="searchForm"
        >
          <el-row>
            <GridItem>
              <el-form-item label="入账时间" prop="time">
                <el-date-picker
                  v-model="form.time"
                  type="datetimerange"
                  value-format="YYYY-MM-DD"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </el-form-item>
            </GridItem>
            <GridItem>
              <el-form-item label="单号" prop="order_no">
                <el-input
                  v-model.trim="form.order_no"
                  placeholder="订单编号/退款编号/支付流水号"
                  clearable
                />
              </el-form-item>
            </GridItem>
          </el-row>
        </el-form>
        <template #operation
          ><el-button type="primary" @click="search()">筛选</el-button>
          <el-button @click="resetForm">清空</el-button>
        </template>
      </FoldPanel>
      <el-divider></el-divider>
      <div class="table-box">
        <export-log
          v-if="total"
          style="margin-bottom: 10px;"
          export_type="MERCHANT_PLATFORM_SETTLEMENT_DETAILS"
          :params="exportParams"
          :page_index="this.page_index"
          :page_size="this.page_size"
          :api="exportApi()"
          :list_type="7"
        />
        <el-table
          :data="tableData"
          border
          :header-cell-style="{
            'text-align': 'center',
            background: '#fafafa',
            color: '#444'
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            type="index"
            label="序号"
            width="80"
          ></el-table-column>
          <el-table-column
            label="支付日期"
            prop="order_pay_time"
          ></el-table-column>
          <el-table-column label="订单编号" prop="order_no"></el-table-column>
          <el-table-column
            label="支付方式"
            prop="pay_method_name"
          ></el-table-column>
          <el-table-column label="商品总价" v-slot="scope">
            <template>
              ￥{{ $filters.formatPrice(scope.row.order_amount) }}</template
            >
          </el-table-column>
          <el-table-column label="技术服务费" v-slot="scope">
            <template>
              ￥{{ $filters.formatPrice(scope.row.service_charge) }}</template
            >
          </el-table-column>
          <el-table-column label="存酒费">
            <template> ￥0 </template>
          </el-table-column>
          <!-- <el-table-column label="平台服务费">
            <template> ￥0 </template>
          </el-table-column> -->
          <el-table-column label="结算金额" v-slot="scope">
            ￥{{ $filters.formatPrice(scope.row.settlement_amount) }}
          </el-table-column>
          <el-table-column label="结算状态"> 结算成功 </el-table-column>
          <el-table-column
            label="入账时间"
            prop="create_time"
          ></el-table-column>
        </el-table>
      </div>
      <template #bottomOperation>
        <el-pagination
          @size-change="search()"
          @current-change="search('keep')"
          v-model:current-page="page_index"
          :page-sizes="[20, 50, 100]"
          v-model:page-size="page_size"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </template>
    </CustomCard>
  </div>
</template>
