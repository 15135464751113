/*
 * @Author: goodLiang
 * @Date: 2023-02-24 16:50:11
 * @LastEditors: goodLiang
 * @LastEditTime: 2023-12-06 09:37:16
 * @FilePath: /vue3-template/src/api/shop.js
 */
import request from '@/utils/request'
const http = '/admin'
// 获取门店列表
export function getShopList(data) {
  return request({
    url: http + '/popSeller/selectPopSellerList',
    method: 'POST',
    data
  })
}

// 获取门店详情
export function getShopDetail(data) {
  return request({
    // url: http + "/seller/querySellerInfo",
    url: http + '/popSeller/queryPopSellerAccountInfo',
    method: 'POST',
    data
  })
}

// 选择商品
export function selectShopGoods(data = {}) {
  return request({
    url: http + '/product/chooseProducts',
    method: 'POST',
    data
  })
}

// 获取货主列表
export function getSuperOwnerList(data = {}) {
  return request({
    url: http + '/superOwner/validSuperOwnerList',
    method: 'POST',
    data
  })
}

// 支付单列表
export function getPaymentList(data = {}) {
  return request({
    url: http + '/webOrder/pageQueryPaySplitInfoList',
    method: 'POST',
    data
  })
}

// 账户信息
export function accountInfo(data) {
  return request({
    // url: http + "/seller/accountInfo",
    url: http + '/seller/selectWebAccount',
    method: 'POST',
    data
  })
}

// 提现列表
export function withDrawalList(data) {
  return request({
    // url: http + "/seller/withDrawalList",
    url: http + '/withdraw/listWeb',
    method: 'POST',
    data
  })
}

// 结算列表
export function yeepayShopSettlementList(data) {
  return request({
    url: http + '/seller/yeepayShopSettlementList',
    method: 'POST',
    data
  })
}

// 结算列表导出(平台/商家端共用)
export function settlementList(data) {
  return request({
    url: http + 'seller/settlementList',
    method: 'POST',
    data
  })
}

// 提现
export function withDrawal(data) {
  return request({
    // url: http + "/seller/withDrawal",
    url: http + '/withdraw/withdrawOrder',
    method: 'POST',
    data
  })
}

// 白名单列表
export function getWhiteList(data) {
  return request({
    url: http + '/popSeller/pageQueryPopSellerList',
    method: 'POST',
    data
  })
}

// 添加白名单
export function addWhiteList(data) {
  return request({
    url: http + '/popSeller//updateWhite',
    method: 'POST',
    data
  })
}

// 银行卡列表
export function getBankList(data) {
  return request({
    url: http + '/seller/bankList',
    method: 'POST',
    data
  })
}

// 新增银行卡
export function addBank(data) {
  return request({
    url: http + '/seller/bankInsert',
    method: 'POST',
    data
  })
}
// 删除银行卡
export function delBank(data) {
  return request({
    url: http + '/seller/bankDelete',
    method: 'POST',
    data
  })
}

// 查询银行总行列表
export function getBankCode(data) {
  return request({
    url: '/pay/account/queryHeadBankList',
    method: 'POST',
    data
  })
}
