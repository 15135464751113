import request from '@/utils/request'
const http = '/admin'
const http1 = '/file/system'
const http2 = '/order'
// 一级分类编辑
export function oneLevelEdit(data) {
  return request({
    url: http + '/classify/addOrUpdateClassify',
    method: 'POST',
    data
  })
}
export function twoLevelEdit(data) {
  return request({
    url: http + '/classify/addOrUpdateTwoClassify',
    method: 'POST',
    data
  })
}

// 一级分类查询
export function oneLevelSearch(data) {
  return request({
    url: http + '/classify/listClassfy',
    method: 'POST',
    data
  })
}
export function oneLevelSelectList(data) {
  return request({
    url: http + '/classify/dropDownClassfy',
    method: 'POST',
    data
  })
}
// 二级分类查询
export function twoLevelSearch(data) {
  return request({
    url: http + '/classify/listTwoClassfy',
    method: 'POST',
    data
  })
}
// 二级分类查询商品信息
export function twoLevelSearchBrand(data) {
  return request({
    url: http + '/classify/listProduces',
    method: 'POST',
    data
  })
}
// 二级已添加商品查询
export function twoLevelOwnBrandMes(data) {
  return request({
    url: http + '/classify/listTwoClassfyProd',
    method: 'POST',
    data
  })
}

// 启用禁用
export function enOrDisableClassify(data) {
  return request({
    url: http + '/classify/enOrDisableClassify',
    method: 'POST',
    data
  })
}
export function delClassifyProd(data) {
  return request({
    url: http + '/classify/delClassifyProd',
    method: 'POST',
    data
  })
}
// 查询商品价格
export function searchBrandPrice(data) {
  return request({
    url: http + '/price/listWineMarketProdPrice',
    method: 'POST',
    data
  })
}
// 商品价格批量导出
export function priceExportMore(data) {
  return request({
    url: http1 + '/price/exportProdPrice',
    method: 'POST',
    responseType: 'arraybuffer',
    data
  })
}
// 商品价格批量修改
export function priceChangeMore(data) {
  return request({
    url: http1 + '/price/batchUpdate',
    method: 'POST',
    data
  })
}
// 商品价格批量复核
export function reviewMore(data) {
  return request({
    url: http + '/price/batchReview',
    method: 'POST',
    data
  })
}
// 商品价格复核
export function review(data) {
  return request({
    url: http + '/price/review',
    method: 'POST',
    data
  })
}
// 商品批量上下架
export function UpOrDownShelvesMore(data) {
  return request({
    url: http + '/price/batchUpOrDownShelves',
    method: 'POST',
    data
  })
}
// 商品上下架
export function upOrDownShelves(data) {
  return request({
    url: http + '/price/upOrDownShelves',
    method: 'POST',
    data
  })
}
// 商品价格查询详情
export function getProdPriceDetails(data) {
  return request({
    url: http + '/price/getWineMarketProdPriceDetails',
    method: 'POST',
    data
  })
}
// // 商品价格复制
// export function copy(data) {
//     return request({
//         url: http + "/price/copy",
//         method: "POST",
//         data
//     });
// }
// 商品价格编辑
export function priceEdit(data) {
  return request({
    url: http + '/price/updateWineMarketProdPrice',
    method: 'POST',
    data
  })
}
// 商品价格查询编辑日志
export function listProdPriceEditLogs(data) {
  return request({
    url: http + '/price/listProdPriceEditLogs',
    method: 'POST',
    data
  })
}
// 存酒库
// 唯一码
export function memberWineStockCodeList(data) {
  return request({
    url: http + '/memberWineStock/memberWineStockCodeList',
    method: 'POST',
    data
  })
}
// sku
export function memberWineStockSkuList(data) {
  return request({
    url: http + '/memberWineStock/memberWineStockSkuList',
    method: 'POST',
    data
  })
}
// 微信送酒
export function giftCardList(data) {
  return request({
    url: http + '/giftCard/list',
    method: 'POST',
    data
  })
}
// 导出sku
export function exportMemberWineStockSkuList(data) {
  return request({
    url: http1 + '/memberWineStock/exportMemberWineStockSkuList',
    method: 'POST',
    responseType: 'arraybuffer',
    data
  })
}
// 导出唯一码
export function exportMemberWineStockItem(data) {
  return request({
    url: http1 + '/memberWineStock/exportMemberWineStockItem',
    method: 'POST',
    responseType: 'arraybuffer',
    data
  })
}
// 导出微信送酒
export function exportGiftCard(data) {
  return request({
    url: http1 + '/memberWineStock/exportGiftCard',
    responseType: 'arraybuffer',
    method: 'POST',
    data
  })
}
// 交易记录
export function webResellSettle(data) {
  return request({
    url: http + '/webResellSettle/queryPage',
    method: 'POST',
    data
  })
}
//

// export function exportGiftCard(data) {
//     return request({
//         url: http1 + "/memberWineStock/exportGiftCard",
//         method: "POST",
//         data
//     });
// }
// 买酒订单 提酒订单
export function queryWineMarketOrderPage(data) {
  return request({
    url: http + '/webOrder/queryWineMarketOrderPage',
    method: 'POST',
    data
  })
}
// 订单详情
export function queryWineMarketDetail(data) {
  return request({
    url: http + '/webOrder/queryWineMarketDetail',
    method: 'POST',
    data
  })
}
// 核验
export function confirmReceiveOrder(data) {
  return request({
    url: http2 + '/order/writeOffPickup',
    method: 'POST',
    data
  })
}
// 打印
export function printSubmitWine(data) {
  return request({
    url: http + '/webOrder/takeUpCommodity',
    method: 'POST',
    data
  })
}
// 买酒订单导出
export function exportWineMarket(data) {
  return request({
    url: http1 + '/order/exportWineMarket',
    method: 'POST',
    responseType: 'arraybuffer',
    data
  })
}
// // 提酒订单导出
// export function printSubmitWine(data) {
//     return request({
//         url: http1 + "/order/exportWineMarket",
//         method: "POST",
//         data
//     });
// }
// 交易记录导出
export function exportResellSettle(data) {
  return request({
    url: http1 + '/order/exportResellSettle',
    method: 'POST',
    responseType: 'arraybuffer',
    data
  })
}
// 委托单
export function askOrderList(data) {
  return request({
    url: http + '/askOrder/list',
    method: 'POST',
    data
  })
}
export function askOrderDetail(data) {
  return request({
    url: http + '/askOrder/detail',
    method: 'POST',
    data
  })
}
export function askOrderExport(data) {
  return request({
    url: http1 + '/askOrder/export',
    responseType: 'arraybuffer',
    method: 'POST',
    data
  })
}
export function querySearchKeyWordsRecord(data) {
  return request({
    url: http + '/dataExpert/querySearchKeyWordsRecord',
    method: 'POST',
    data
  })
}

// 商家商品管理-列表
export function pageQuerySellerMemberWineList(data) {
  return request({
    url: http + '/popSeller/pageQuerySellerMemberWineList',
    method: 'POST',
    data
  })
}

// 商家商品管理-商品上下架
export function batchUpdateMemberWineStatusByWhiteSeller(data) {
  return request({
    url: http + '/popSeller/batchUpdateMemberWineStatusByWhiteSeller',
    method: 'POST',
    data
  })
}

// 商家商品管理-导出
export function exportPopSellerProductList(data) {
  return request({
    url: '/file/system/exportPopSellerProductList',
    method: 'POST',
    data,
    responseType: 'blob'
  })
}

// 商家商品管理-是否展示添加商品按钮
export function queryStaffInfo(data) {
  return request({
    url: http + '/queryStaffInfo',
    method: 'POST',
    data
  })
}

// 商家商品管理-白名单未拉取商品
export function pageQuerySellerAbsentMemberWineList(data) {
  return request({
    url: http + '/popSeller/pageQuerySellerAbsentMemberWineList',
    method: 'POST',
    data
  })
}

// 商家商品管理-批量添加白名单未拉取商品
export function batchAddMemberWineByWhiteSeller(data) {
  return request({
    url: http + '/popSeller/batchAddMemberWineByWhiteSeller',
    method: 'POST',
    data
  })
}

// 商家商品管理-详情-获取酒市商品建议零售价格
export function getSuggestedPrices(data) {
  return request({
    url: http + '/memberWineStock/getSuggestedPrices',
    method: 'POST',
    data
  })
}

// 商家商品管理-详情-校验酒市商品价格设置
export function checkWineStockProductPriceSetting(data) {
  return request({
    url: http + '/memberWineStock/checkWineStockProductPriceSetting',
    method: 'POST',
    data
  })
}

// 商家商品管理-详情-编辑酒库商品信息
export function editPopWineStockProduct(data) {
  return request({
    url: http + '/memberWineStock/editPopWineStockProduct',
    method: 'POST',
    data
  })
}

// 商家商品管理-详情-编辑酒库商品信息日志分页查询
export function pageQueryWineStockProductLog(data) {
  return request({
    url: http + '/memberWineStock/pageQueryWineStockProductLog',
    method: 'POST',
    data
  })
}

// 交易-已卖出商品列表
export function popOrderList(data) {
  return request({
    url: http + '/popOrder/list',
    method: 'POST',
    data
  })
}

// 交易-已卖出商品列表-导出
export function popOrderExport(data) {
  return request({
    url: http1 + '/popOrder/export',
    method: 'POST',
    data
    // responseType: 'blob'
  })
}

// 交易-已卖出商品列表-导出记录
export function exportLogList(data) {
  return request({
    url: http + '/exportLog/list',
    method: 'POST',
    data
  })
}

// 交易-已卖出商品详情
export function popOrderDetail(data) {
  return request({
    url: http + '/popOrder/detail',
    method: 'POST',
    data
  })
}

// 优选-买酒订单列表
export function listWebWineOrder(data) {
  return request({
    url: http + '/webOrder/listWebWineOrder',
    method: 'POST',
    data
  })
}

// 优选-买酒订单列表-导出
export function orderExportWebWineOrder(data) {
  return request({
    url: http1 + '/order/exportWebWineOrder',
    method: 'POST',
    data
    // responseType: 'blob'
  })
}

// 校验修改地址
export function selectRefundAddressStatus(data) {
  return request({
    url: http + '/refundAddress/selectRefundAddressStatus',
    method: 'POST',
    data
  })
}

// 根据sku查询pop商家信息
export function listSkuPopSellerMes(data) {
  return request({
    url: http + '/price/listSkuPopSellerMes',
    method: 'POST',
    data
  })
}

// 根据sku查询商家信息库存信息
export function selectPopMemberWineItem(data) {
  return request({
    url: http + '/popSeller/selectPopMemberWineItem',
    method: 'POST',
    data
  })
}

// 提现列表导出(平台/商家端共用)
export function asynchronousExportOfWithdrawalDetails(data) {
  return request({
    url: http1 + '/asynchronousExportOfWithdrawalDetails',
    method: 'POST',
    data
  })
}

// 结算列表导出(平台/商家端共用)
export function asynchronousExportOfSettlementDetails(data) {
  return request({
    url: http1 + '/asynchronousExportOfSettlementDetails',
    method: 'POST',
    data
  })
}
